import React, { useState } from "react"
import Layout from "../../components/layout"
import { useLanguages } from "../../hooks/useLanguages"
import HubspotForm from "react-hubspot-form"
import EmbedVideoFrame from "../../components/EmbedVideoFrame"
import Helmet from "react-helmet"

const ShrmIAC24 = ({ location, abVersion }) => {
  const [mobileDropdown, setmobileDropdown] = useState(false)
  const [link, setlink] = useState("")
  const [mobileProductsOpen, setmobileProductsOpen] = useState(false)
  const [mobileResourcesOpen, setmobileResourcesOpen] = useState(false)

  const handleClick = () => {
    setlink("https://www.youtube.com/embed/RRdx04fAUds?si=VRIpi49JJFv2s5Vl")
  }

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }

  const handleMobileProducts = () => {
    setmobileProductsOpen(!mobileProductsOpen)
    setmobileResourcesOpen(false)
  }

  const handleMobileResources = () => {
    setmobileResourcesOpen(!mobileResourcesOpen)
    setmobileProductsOpen(false)
  }

  const [currLanguage] = useLanguages()

  const shrmTech24 = (
    <>
      Vantage Circle at SHRM<span className="text-orange">Tech'23</span>
    </>
  )

  return (
    <Layout location={location} pageLanguage={currLanguage}>
      <Helmet>
        <title>Vantage Circle | SHRM IAC'24</title>

        <meta
          name="description"
          content="Vantage Circle presents SHRM IAC'24, Annual HR Conference, New Delhi"
        />
        <meta property="twitter:title" content="Vantage Circle | SHRM IAC'24" />
        <meta name="robots" content="index" />

        <meta
          property="twitter:description"
          content="Vantage Circle presents SHRM IAC'24, Annual HR Conference, New Delhi"
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/events/shrmIAC24"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta property="og:title" content="Vantage Circle | SHRM IAC'24" />

        <meta
          property="og:description"
          content="Vantage Circle presents SHRM IAC'24, Annual HR Conference, New Delhi"
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/events/shrmIAC24"
        ></link>
      </Helmet>

      <section className="w-full bg-indigo-100 shadow-darkgray flex place-content-center z-50 py-2 sticky top-0">
        <div className="w-full lg:w-11/12 2xl:w-9/12 flex justify-between px-5 2xl:px-24 items-center">
          <div className="w-2/3 lg:w-5/12 py-3 lg:py-3">
            <div>
              <img
                className="w-auto vc-logo"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/vcxIAC-logo.png"
                alt="Vantage Circle Logo"
                width="273"
                height="40"
              />
            </div>
          </div>
          <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-10/12">
            <a
              href="#home"
              className="text-gray-100 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Home
            </a>
            <a
              href="#eventDetails"
              className="text-gray-100 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Event Details
            </a>
            <a
              href="#speakers"
              className="text-gray-100 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Speakers
            </a>
            <div className="flex px-0 xl:px-2 lg:w-2/6 xl:w-3/12 justify-center xl:justify-end items-center">
              <a
                href="#form"
                className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-6 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg"
              >
                Request a Demo
              </a>
            </div>
          </div>

          <div
            className="flex lg:hidden justify-center items-center"
            onClick={showmobileDropdown}
          >
            <div className="rounded-full bg-white p-2 cursor-pointer">
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  strokewidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          className={
            mobileDropdown
              ? `overflow-x-hidden overflow-y-scroll absolute top-16 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs z-50` +
                (mobileProductsOpen || mobileResourcesOpen ? " h-96" : "")
              : "hidden"
          }
        >
          <div className="w-full flex flex-col justify-center items-start">
            <a
              href="#home"
              className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Home
            </a>
            <a
              href="#eventDetails"
              className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Event Details
            </a>
            <a
              href="#speakers"
              className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              Speakers
            </a>
            <a href="/request-demo/" className="w-full vc-btn-orange-v2 my-2">
              Request a Demo
            </a>
          </div>
        </div>
      </section>

      <section
        className="w-full shrmBG flex items-start xl:items-center justify-center py-10 xl:py-16 px-5"
        id="home"
      >
        <div className="xl:flex w-full xl:max-w-7xl px-4 lg:px-0 xl:px-5 mx-auto">
          <div className="xl:w-7/12 flex flex-col place-content-center">
            <div className="my-3 flex items-center">
              {/* <h2 className="pl-1 text-left heroTagStyle-OrangeText">VANTAGE CIRCLE PRESENTS SHRMTECH'24</h2> */}
            </div>
            <h3 className="homepage-div-heading text-purple-100 mb-8">
              Recognise & Nurture Productive Behaviours for Business Growth​
              with our R&R Program Success toolkit
            </h3>
            {/* <picture className="xl:flex items-center">
                <source
                  className="z-10 relative"
                  media="(min-width: 1025px)"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Shrm-mobile-version-logo.webp"
                  type="image/webp"
                  alt="Vantage Rewards"
                  width="300"
                />
                <source
                  className="z-10 relative"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Shrm-mobile-version-logo.webp"
                  type="image/webp"
                  alt="Vantage Rewards"
                  width="300"
                />
                <img
                  className="z-10 relative mx-auto lg:mx-0"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Shrm-mobile-version-logo.png"
                  alt="Vantage Rewards"
                  width="300"
                />
              </picture> */}
            <div className="shadow-xl border-2 border-white rounded overflow-hidden cursor-pointer relative transform scale-100 2xl:scale-100 mt-5 xl:ml-2">
              <img
                className={link.length ? "hidden" : "block"}
                onClick={handleClick}
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/product-explainer-thumbnail-shrm.jpg"
                width="868px"
              />
              <div className="">
                <iframe
                  className={link.length ? "block" : "hidden"}
                  src={link}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  width="868"
                  height="350"
                  title="What's it like to Work at Vantage Circle ? | Corporate Culture | Careers at Vantage Circle"
                  allowfullscreen
                />
              </div>
            </div>
          </div>

          <div className="w-full md:w-9/12 xl:w-4/12 flex items-center mx-auto xl:mr-0 2xl:ml-auto">
            <div
              id="form"
              className="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 flex flex-col place-items-center rounded-lg px-6 xl:pl-8 xl:pr-6 py-6 border shadow-xl xl:ml-4 bg-indigo-100"
            >
              <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                Get the R&R Program Success Toolkit with a Personalised Product
                Tour
              </h2>
              {/* <p class="text-base mb-8 text-center mt-2">
                    Get a personalized walkthrough of Vantage Rewards from a friendly member of our team
                </p> */}
              <div class="w-full flex justify-center items-center whiteConsent lato">
                <HubspotForm
                  portalId="6153052"
                  formId="78af1133-f8a1-4ac4-a1ec-0207c139b51b"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </div>
              <p class="text-sm text-center text-purple-100">
                We safeguard your{" "}
                <a href="/privacy-policy/" class="text-orange">
                  Privacy
                </a>
              </p>
              <script
                charset="utf-8"
                type="text/javascript"
                src="//js.hsforms.net/forms/embed/v2.js"
              ></script>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full flex place-content-center py-10 xl:py-16">
        <div className="max-w-7xl mx-auto">
          <h2 className="homepage-section-heading text-center">
            Unlocking Employee's Potential:
          </h2>
          <h2 className="homepage-div-heading text-center">
            Get the deliverables you need with this toolkit
          </h2>

          <div className="w-10/12 lg:w-full xl:max-w-7xl mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-6 place-content-center rounded-xl p-5 border-2 border-white mt-3 md:mt-10">
            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/6.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/6.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/6.png"
                    alt="Vantage Rewards"
                    width="208"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="2/3 my-auto">
                <p className="text-xl my-auto pl-3 text-gray-500">
                  R&R Effectiveness Scorecard
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/5.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/5.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/5.png"
                    alt="Vantage Rewards"
                    width="208"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  R&R Product Guide
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/4.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/4.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="208"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/4.png"
                    alt="Vantage Rewards"
                    width="208"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Long Service Awards Guide
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/3.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="290"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/3.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="290"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/3.png"
                    alt="Vantage Rewards"
                    width="290"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Service Yearbook Sample
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/2.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/2.webp"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/2.png"
                    alt="Vantage Rewards"
                    width="250"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Phygital Recognition Guide
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/teams2.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/teams2.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/teams2.png"
                    alt="Vantage Rewards"
                    width="250"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Recognise with Microsoft Teams
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/aireReportIcon9.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/aireReportIcon9.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/aireReportIcon9.png"
                    alt="Vantage Rewards"
                    width="250"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Annual R&R Report (India): 2024-25
                </p>
              </div>
            </div>

            <div className="flex place-content-center rounded-xl bg-gray-100 border-2 border-white p-5 shadow-lg min-h-180px">
              <div className="w-1/3 my-auto">
                <picture>
                  <source
                    className="z-10 relative"
                    media="(min-width: 1025px)"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/masterClass5.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <source
                    className="z-10 relative"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/masterClass5.png"
                    type="image/webp"
                    alt="Vantage Rewards"
                    width="250"
                  />
                  <img
                    className="z-10 relative mx-auto lg:mx-0"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/masterClass5.png"
                    alt="Vantage Rewards"
                    width="250"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="w-2/3 my-auto">
                <p className="text-xl my-auto pl-5 text-gray-500">
                  Profile Badging Guide
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="w-full flex place-content-center overflow-hidden">
              <div className="max-w-7xl mx-auto">
                 <EmbedVideoFrame 
                  videoLink = {"https://www.youtube.com/embed/kt9UEmI3y9s"} 
                  title={shrmTech24}
                  thumbnail = {"https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/thumbnail-2.png"} />
              </div>
        </section> */}

      <section className="py-10 xl:py-16 w-full flex place-content-center bg-indigo-100 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-5 lg:px-8 xl:px-0 lg:flex place-content-center">
          <div className="my-auto">
            <h2 className="homepage-div-heading text-purple-100 text-center lg:text-left">
              Event Highlights: SHRM <span className="text-orange">IAC'24</span>
            </h2>
            <div className="grid grid-cols-2 place-content-center gap-6 lg:w-10/12 mt-10">
              {/* <div className="rounded-lg p-3 h-auto min-h-120px lg:min-h-100px flex items-center bg-white">
                  <p className="m-0 my-auto px-2 lg:px-5 text-gray-500"><span className="text-orange font-bold">Grand Reveal</span> - AIR<sup>e</sup> Benchmarking Report 2024</p>
                </div> */}
              <div className="rounded-lg p-3 h-auto min-h-120px lg:min-h-100px flex items-center bg-white">
                <p className="m-0 my-auto px-2 lg:px-5 text-gray-500">
                  Enlightening Keynote Sessions
                </p>
              </div>
              <div className="rounded-lg p-3 h-auto min-h-120px lg:min-h-100px flex items-center bg-white">
                <p className="m-0 my-auto px-2 lg:px-5 text-gray-500">
                  Insightful Panel Discussions
                </p>
              </div>
              <div className="rounded-lg p-3 h-auto min-h-120px lg:min-h-100px flex items-center bg-white">
                <p className="m-0 my-auto px-2 lg:px-5 text-gray-500">
                  Roundtable Discussion
                </p>
              </div>
            </div>
            <a
              href="https://registration.shrmconference.org/tech/"
              className="vc-new-orange-btn-rounded-full my-10 lg:mt-10 lg:mb-0 mx-auto lg:mx-0"
              target="blank"
            >
              Register for the event
            </a>
          </div>
          <div className="flex place-content-center my-auto z-10">
            <picture>
              <source
                className="z-10 relative"
                media="(min-width: 1025px)"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/IAC-Speaker-Collage.png"
                type="image/webp"
                alt="Vantage Rewards"
                width="350"
              />
              <source
                className="z-10 relative"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/IAC-Speaker-Collage.png"
                type="image/webp"
                alt="Vantage Rewards"
                width="200"
              />
              <img
                className="z-10 relative mx-auto lg:mx-0"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/IAC-Speaker-Collage.png"
                alt="Vantage Rewards"
                width="200"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <div
          className="rounded-full absolute -bottom-16 -right-20 2xl:right-52 2xl:-bottom-24 hidden lg:block z-0"
          style={{ background: "#454567", height: "30rem", width: "30rem" }}
        ></div>
      </section>

      <section
        className="py-10 xl:pt-24 flex place-content-center relative overflow-hidden"
        id="speakers"
      >
        <div className="hidden lg:block absolute top-0 left-0">
          <picture>
            <source
              className="z-10 relative"
              media="(min-width: 1025px)"
              srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Background-vector.webp"
              type="image/webp"
              alt="Vantage Rewards"
              width="1500"
            />
            <source
              className="z-10 relative"
              srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Background-vector.webp"
              type="image/webp"
              alt="Vantage Rewards"
              width="1000"
            />
            <img
              className="z-10 relative mx-auto lg:mx-0"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/"
              alt="Vantage Rewards"
              width="300"
              loading="lazy"
            />
          </picture>
        </div>
        <div className="hidden lg:block absolute bottom-0 right-0">
          <picture>
            <source
              className="z-10 relative"
              media="(min-width: 1025px)"
              srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Background-vector-2.webp"
              type="image/webp"
              alt="Vantage Rewards"
              width="1500"
            />
            <source
              className="z-10 relative"
              srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Background-vector-2.webp"
              type="image/webp"
              alt="Vantage Rewards"
              width="1000"
            />
            <img
              className="z-10 relative mx-auto lg:mx-0"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/"
              alt="Vantage Rewards"
              width="300"
              loading="lazy"
            />
          </picture>
        </div>
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="homepage-section-heading text-center">
            Speaker Profiles
          </h2>

          <div className="grid md:grid-cols-2 gap-10 lg:grid-cols-3 my-5 md:my-10">
            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture>
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-12-2.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-12-2.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-12-2.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">
                    Sathish Gopalaiah
                  </h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">
                President, Technology & Transformation
              </p>
              <p className="mt-1 text-center">Deloitte South Asia</p>
            </div>
            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture className="relative">
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-14.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-14.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-14.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                    {/* <a href='https://www.linkedin.com/in/adriangostick/' className='absolute top-0 right-0 z-10'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a> */}
                  </picture>
                  {/* <div className='rounded-full mx-auto'>
                          <a href='https://www.linkedin.com/in/adriangostick/' className='absolute top-6 right-12 z-10'><img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/linkedin.png' width="40" /></a>
                        </div> */}
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">
                    Sunita Rebecca Cherian
                  </h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">
                Chief Culture Officer & Senior Vice President, Corporate Human
                Resources, Wipro Member of Wipro Executive Committee
              </p>
              <p className="mt-1 text-center opacity-0"></p>
            </div>

            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture>
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Partha-image.webp"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Partha-image.webp"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Partha-image.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">Partha Neog</h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">CEO and Co-founder,</p>
              <p className="mt-1 text-center">Vantage Circle</p>
            </div>
            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture>
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-13-2.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-13-2.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/DHHT-Speaker-13-2.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">Nanjappa B S</h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">Senior Vice President, HR,</p>
              <p className="mt-1 text-center">Infosys</p>
            </div>

            {/* <div className="grid items-start lg:place-content-center">
                    <div className="flex flex-col place-content-center p-3">
                      <div className="">
                        <picture>
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/Dedeepya.png"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <source
                            className="z-10 relative"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/Dedeepya.png"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <img
                            className="z-10 relative mx-auto lg:mx-0"
                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/Dedeepya.png"
                            alt="Vantage Rewards"
                            width="300"
                            loading= "lazy"
                          />
                        </picture>
                      </div>
                      <div classname= ""><h3 className="mx-auto text-2xl text-center">Dedeepya Ajith John</h3></div>
                    </div>
                    <p className="text-center m-0 p-0">Senior Director - Advisory & BD, SHRM, India & APAC</p>
                    <p className="mt-1 text-center"></p>
                  </div>    */}

            {/* <div className="grid items-start lg:place-content-center">
                    <div className="flex flex-col place-content-center p-3">
                      <div className="">
                        <picture>
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/deepjyoti-image.webp"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <source
                            className="z-10 relative"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/deepjyoti-image.webp"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <img
                            className="z-10 relative mx-auto lg:mx-0"
                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/deepjyoti-image.png"
                            alt="Vantage Rewards"
                            width="300"
                            loading= "lazy"
                          />
                        </picture>
                      </div>
                      <div classname= ""><h3 className="mx-auto text-2xl text-center">Deepjyoti Saha</h3></div>
                    </div>
                    <p className="text-center m-0 p-0">Senior Product Manager,</p>
                    <p className="mt-1 text-center">Microsoft Teams</p>
                  </div>   */}

            {/* <div className="grid items-start lg:place-content-center">
                    <div className="flex flex-col place-content-center p-3">
                      <div className="">
                        <picture>
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/saurabh-iamge.webp"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <source
                            className="z-10 relative"
                            srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/saurabh-iamge.webp"
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="300"
                          />
                          <img
                            className="z-10 relative mx-auto lg:mx-0"
                            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/saurabh-iamge.png"
                            alt="Vantage Rewards"
                            width="300"
                            loading= "lazy"
                          />
                        </picture>
                      </div>
                      <div classname= ""><h3 className="mx-auto text-2xl text-center">Saurabh Deshpande</h3></div>
                    </div>
                    <p className="text-center m-0 p-0">Director, CoE People and Culture,</p>
                    <p className="mt-1 text-center">Vantage Circle</p>
                  </div> */}

            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture>
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/popli-image.webp"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/popli-image.webp"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/popli-image.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">Pallav Popli</h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">
                CRO and Employee Engagement Evangelist, Vantage Circle
              </p>
              <p className="mt-1 text-center"></p>
            </div>

            <div className="grid items-start lg:place-content-center">
              <div className="flex flex-col place-content-center p-3">
                <div className="">
                  <picture>
                    <source
                      className="z-10 relative"
                      media="(min-width: 1025px)"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1728554639/vantagecircle/2024/ganesh-iyer.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <source
                      className="z-10 relative"
                      srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1728554639/vantagecircle/2024/ganesh-iyer.png"
                      type="image/webp"
                      alt="Vantage Rewards"
                      width="300"
                    />
                    <img
                      className="z-10 relative mx-auto lg:mx-0"
                      src="https://res.cloudinary.com/vantagecircle/image/upload/v1728554639/vantagecircle/2024/ganesh-iyer.png"
                      alt="Vantage Rewards"
                      width="300"
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div classname="">
                  <h3 className="mx-auto text-2xl text-center">
                    Ganesh D Iyer
                  </h3>
                </div>
              </div>
              <p className="text-center m-0 p-0">
                Global head - Learnign & Developement, Rewards & recognition,
              </p>
              <p className="mt-1 text-center"> IBS Software</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-10 xl:py-16 w-full px-5 relative overflow-hidden"
        style={{ background: "#eeeef7" }}
        id="eventDetails"
      >
        <div
          class="w-96 h-96 hidden lg:block absolute -bottom-40 2xl:-bottom-24 -right-28 2xl:-right-20 border rounded-full z-0"
          style={{
            borderWidth: "35px",
            background: "transparent",
            borderColor: "rgb(220,220,235)",
          }}
        ></div>

        <div
          class="w-96 h-96 z-0 hidden lg:block absolute -top-40 2xl:-top-24 -left-28 2xl:-left-20 border rounded-full z-0"
          style={{
            borderWidth: "35px",
            background: "transparent",
            borderColor: "rgb(220,220,235)",
          }}
        ></div>

        <div className="max-w-6xl mx-auto">
          <h2 className="homepage-section-heading text-center">
            SHRMIAC'24 Event Details
          </h2>
          <div className="lg:flex mt-8 lg:mt-12">
            <div className="flex h-1/2 justify-center lg:w-4/12">
              <h2 className="bg-indigo-100 homepage-small-heading text-purple-100 py-3 px-5 z-10">
                Day 1 | 17th Oct, 2024
              </h2>
            </div>
            <ul className="purplebullets lg:w-5/12 mt-5 lg:ml-10 xl:mt-0 place-content-center grid">
              <li className="">
                <span className="font-bold">Keynote Session</span>- Flipping the
                'R's: Importance of Recognition in Building Culture
              </li>
            </ul>
            <div className="lg:w-1/12 mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid">
              <p className="text-gray-500">10:45 AM</p>
            </div>
            <div className="lg:w-2/12 mt-0 lg:mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid">
              <p className="text-gray-500">Main Stage</p>
            </div>
          </div>

          <div className="lg:flex mt-8">
            <div className="flex h-1/2 justify-center lg:w-4/12">
              <h2 className="bg-indigo-100 homepage-small-heading text-purple-100 py-3 px-5 z-10">
                Day 2 | 18th Oct, 2024
              </h2>
            </div>
            <ul className="purplebullets lg:w-5/12 mt-5 lg:ml-10 xl:mt-0 place-content-center grid">
              <li className="">
                <span className="font-bold">Panel Discussions</span>- The
                Leadership Edge: Transforming Culture into Strategic Power
              </li>
            </ul>
            <div className="lg:w-1/12 mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid z-10">
              <p className="text-gray-500">12:30 PM</p>
            </div>
            <div className="lg:w-2/12 mt-0 lg:mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid z-10">
              <p className="text-gray-500">Main Stage</p>
            </div>
          </div>
          <div className="lg:flex mt-1">
            <div className="flex h-1/2 justify-center lg:w-4/12">
              <div className="flex h-1/2 justify-center lg:w-4/12">
                <h2 className="py-3 px-5 z-10"></h2>
              </div>
            </div>
            <ul className="purplebullets lg:w-5/12 mt-5 lg:ml-10 xl:mt-0 place-content-center grid">
              <li className="">
                <span className="font-bold">Roundtable</span>- Fostering
                Productivity: Shaping Employee Behaviour Through Recognition &
                Positive Reinforcement{" "}
              </li>
            </ul>
            <div className="lg:w-1/12 mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid z-10">
              <p className="text-gray-500">1 PM</p>
            </div>
            <div className="lg:w-2/12 mt-0 lg:mt-5 ml-5 lg:ml-10 xl:mt-0 place-content-center lg:grid z-10">
              <p className="text-gray-500">Conference Room</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 lg:py-0 px-5 bgSpecialPackageSHRM overflow-hidden relative grid items-center">
        <div className="max-w-7xl mx-auto lg:flex">
          <div className="w-full flex  flex-col justify-center items-start">
            <p className="text-purple-100 text-center lg:text-left homepage-small-heading pt-10 px-5 lg:p-0">
              Download the special package for SHRM attendees to enhance{" "}
              <span className="">
                Productive Employee Behaviour and Business Growth
              </span>
              &nbsp;through R&R Program.
            </p>
            <a
              href="#form"
              className="vc-new-orange-btn-rounded-full my-10 mx-auto lg:mx-0"
            >
              Download now
            </a>
          </div>
          <div className="lg:w-1/2 flex justify-center items-end">
            <div className="">
              <picture>
                <source
                  className="z-10 relative"
                  media="(min-width: 1025px)"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Girl-1.webp"
                  type="image/webp"
                  alt="Vantage Rewards"
                  width="500"
                />
                <source
                  className="z-10 relative"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Girl-1.webp"
                  type="image/webp"
                  alt="Vantage Rewards"
                  width="500"
                />
                <img
                  className="z-10 relative  mx-auto lg:mx-0"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/Girl-1.png"
                  alt="Vantage Rewards"
                  width="300"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ShrmIAC24
